<template>
	<div>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">添加政策</a-button>

				</slot>
			</span>

			<a-modal v-model:visible="visible" width="850px" title="添加政策" @cancel="onCancel">
				<template #footer>
					<a-button @click="onCancel">取消</a-button>
					<a-button v-if="!disabled" @click="onOk(false)" type="primary">添加并返回</a-button>
					<!-- <a-button @click="onOk(true)" type="primary">添加并继续</a-button> -->
				</template>
				<a-spin :spinning="loading">
					<a-form ref="formRef" :model="modelRef" scrollToFirstError name="formRef"
						:labelCol="{span: 4 }"
						:wrapperCol="{span: 18 }"
					>

						<div v-if="type === 'preferential'">
							<a-form-item label="影院组织" name="organizationId">
								<a-select :disabled="disabled" v-model:value="modelRef.organizationId" allowClear placeholder="请选择影院组织" show-search :filter-option="filterOption" @change="getCinemaList">
									<a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item label="所属影院" name="cinemaId" extra="选择所属影院后开启其它选择项">
								<a-select :disabled="disabled" v-model:value="modelRef.cinemaId" allowClear placeholder="请选择所属影院" show-search :filter-option="filterOption" @change="onCinemaChange">
									<a-select-option v-for="item in cinemaAllList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<a-form-item label="政策名称" name="pricingName" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input :disabled="disabled" v-model:value="modelRef.pricingName" placeholder="请输入政策名称"></a-input>
						</a-form-item>
            <a-form-item label="使用影院" name="pricingName" :rules="[{required: true, message: '必填项不允许为空'}]" v-if="type != 'preferential' && type !='tiktok' && type !='timesCard' && type !='yearCard'">
              <a-form-item-rest>
                <selectCinema :id="value.organizationId" :disabled="isEdit" :selectItem="modelRef.cinemaTableList" @change="(dataList) => { modelRef.cinemaTableList = dataList; modelRef.cinemaId=modelRef.cinemaTableList[0].id;id=modelRef.cinemaTableList[0].id;policyIds=modelRef.cinemaTableList[0].id;modelRef.filmList=[];movieList=[];modelRef.hallList=[];hallDataList=[]}" :policyIds="policyIds" showType="policy" type="radio" :polCinedmaIds="polCinedmaIds"></selectCinema>

              </a-form-item-rest>
                <a-table rowKey="id" :columns="cinemaColumns" :dataSource="modelRef.cinemaTableList" size="small" :pagination="false">
                  <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'action'">
                      <a-button :disabled="isEdit" type="link" @click="onDeleteCinema(record, index)">删除</a-button>
                    </template>
                  </template>
                </a-table>
<!--              </div>-->
            </a-form-item>

						<a-row>
							<a-col :offset="1" :span="22">
								<a-row style="padding-left:10px;">
									<a-col :span="7">
										<a-form-item label="优先级别" name="pricingLevel" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.pricingLevel" :min="0" :precision="0" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="7" v-if="!['yearCard'].includes(type)">
										<a-form-item v-if="type === 'stored'" label="结算金额" name="deductionAmount" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.deductionAmount" :min="0" :precision="2" placeholder="请输入"></a-input-number>
										</a-form-item>
										<a-form-item v-else label="补差金额" name="diffPrice" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.diffPrice" :min="0" :precision="2" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="7">
										<a-form-item label="是否启用" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-switch :disabled="disabled" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>


            <a-form-item label="是否使用最低票价补差" name="isFilmMinPrice" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 6}">
              <a-switch :disabled="disabled" v-model:checked="modelRef.isFilmMinPrice" checked-children="启用" un-checked-children="禁用" @change="onPriceInfoSwitchChange"/>
            </a-form-item>
            <div v-if="modelRef.isFilmMinPrice">
              <a-button style="margin-bottom: 20px" @click="onAddPriceInfo">添加配置</a-button>
              <a-table :columns="priceColumns" :data-source="priceInfo" bordered>
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'No'">
                    {{ index + 1}}
                  </template>
                  <template v-if="column.key === 'priceInfo'">
                    <a-row>
                      <a-col :span="8">
                        <a-input-number :max="record.max" min="0" v-model:value="record.min" />
                        (包含)
                      </a-col>
                      <a-col :span="2" style="text-align: center;display: flex;align-items: center;justify-content: center;">到</a-col>
                      <a-col :span="6">
                        <a-input-number :min="record.min" @change="onMaxChange(record, index, 'max')" :disabled="record.isMax" v-model:value="record.max" />
                      </a-col>
                      <a-col :span="4" style="text-align: center;display: flex;align-items: center;justify-content: center;">
                        <a-checkbox :disabled="!!record.max" @change="onMaxChange(record, index, 'isMax')" v-model:checked="record.isMax" style="display: flex;align-items: center;">最大</a-checkbox>
                      </a-col>
                      <a-col :span="4">(不包含)</a-col>
                    </a-row>
                  </template>
                  <template v-if="column.key === 'diffPrice'">
                    <a-input-number v-model:value="record.diffPrice" />
                  </template>
                  <template v-if="column.key === 'action'">
                    <a @click="onDelPriceInfo(index)">删除</a>
                  </template>
                </template>
              </a-table>
              <p style="color: red;">说明：如果最低票价范围重复，则取序号小的补差金额。</p>
            </div>

						<a-row v-if="!['yearCard', 'preferential', 'tiktok'].includes(type)">
							<a-col :offset="1" :span="22">
								<a-row style="padding-left:10px;">
									<a-col :span="7">
										<a-form-item label="场次限购" name="singleCount" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.singleCount" :min="0" :precision="0" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="7">
										<a-form-item label="影片限购" name="filmCount" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.filmCount" :min="0" :precision="0" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>

						<p v-if="!['yearCard', 'preferential', 'tiktok'].includes(type)" style="text-align: center;color:red;">
							 场次限购：限制场次限购票数（单场次限购数）。
						</p>

						<p v-if="!['yearCard', 'preferential', 'tiktok'].includes(type)" style="text-align: center;color:red;">
							  影片限购：限制影片限购票数（每部影片限购数）
						</p>

						<a-form-item label="规则类型" name="versionType" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-radio-group :disabled="disabled" v-if="['yearCard'].includes(type)" v-model:value="modelRef.versionType" button-style="solid" >
								<a-radio-button :value="3">批量设置</a-radio-button>
								<a-radio-button :value="4">场次设置</a-radio-button>
							</a-radio-group>

							<a-radio-group :disabled="disabled" v-else v-model:value="modelRef.versionType" button-style="solid">
								<a-radio-button :value="3">批量定价</a-radio-button>
								<a-radio-button :value="4" :disabled="type === 'preferential' && !modelRef.cinemaId">场次定价</a-radio-button>
							</a-radio-group>
						</a-form-item>

						<div v-if="modelRef.versionType === 3">
							<a-form-item label="影片规则" :rules="[{required: true, message: '必填项不允许为空'}]">
								<div class="ui-flex">
									<a-radio-group :disabled="disabled" v-model:value="modelRef.filmType" button-style="solid" @change="modelRef.filmList = [];movieList = [];">
										<a-radio-button :value="1">不限制</a-radio-button>
										<a-radio-button :value="2" :disabled="type === 'preferential' && !modelRef.cinemaId">指定可用影片</a-radio-button>
										<a-radio-button :value="3" :disabled="type === 'preferential' && !modelRef.cinemaId">指定不可用影片</a-radio-button>
									</a-radio-group>
									<span v-if="modelRef.filmType !== 1" style="margin-left: 20px;">
										<movie :id="type === 'preferential' ? modelRef.cinemaId : id" :disabled="disabled" v-model:value="modelRef.filmList" @change="(dataList) => { movieList = dataList;modelRef.movieList = dataList; }"></movie>
									</span>
								</div>
								<div v-if="movieList.length" style="margin: 10px 0;">
									<a-table rowKey="id" :columns="movieColumns" :dataSource="movieList" size="small" :pagination="false">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'action'">
												<a-button :disabled="disabled" type="link" @click="onDeleteMovie(record, index)">删除</a-button>
											</template>
										</template>
									</a-table>
								</div>
							</a-form-item>

							<a-form-item label="影厅规则" :rules="[{required: true, message: '必填项不允许为空'}]">
								<div class="ui-flex">
									<a-radio-group :disabled="disabled" v-model:value="modelRef.hallType" button-style="solid" @change="modelRef.hallList = [];hallDataList = [];">
										<a-radio-button :value="1">不限制</a-radio-button>
										<a-radio-button :value="2" :disabled="type === 'preferential' && !modelRef.cinemaId">指定可用影厅</a-radio-button>
										<a-radio-button :value="3" :disabled="type === 'preferential' && !modelRef.cinemaId">指定不可用影厅</a-radio-button>
									</a-radio-group>
									<span v-if="modelRef.hallType !== 1" style="margin-left: 20px;">
										<cinema :disabled="disabled" :id="type === 'preferential' ? modelRef.cinemaId : id" v-model:value="modelRef.hallList" @change="(dataList) => {hallDataList = dataList; modelRef.hallDataList = dataList;}"></cinema>
									</span>
								</div>
								<div v-if="hallDataList.length" style="margin: 10px 0;">
									<a-table rowKey="id" :columns="hallColumns" :dataSource="hallDataList" size="small" :pagination="false">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'action'">
												<a-button :disabled="disabled" type="link" @click="onDeleteHall(record, index)">删除</a-button>
											</template>
										</template>
									</a-table>
								</div>
							</a-form-item>

							<a-form-item label="视觉规则" name="visualType" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-radio-group :disabled="disabled" v-model:value="modelRef.visualType" button-style="solid" @change="modelRef.dimensionalList = []">
									<a-radio-button :value="1">不限制</a-radio-button>
									<a-radio-button :value="2">指定可用视觉</a-radio-button>
									<a-radio-button :value="3">指定不可用视觉</a-radio-button>
								</a-radio-group>
							</a-form-item>

							<a-form-item label="公共假期" name="isHoliday" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-switch :disabled="disabled" v-model:checked="modelRef.isHoliday" checked-children="可用" un-checked-children="不可用" />
							</a-form-item>

							<a-form-item v-if="modelRef.visualType !== 1" label="选择视觉" name="dimensionalList" :rules="[{required: true, message: '必选项不允许为空'}]">
								<a-form-item-rest>
									<a-checkbox :disabled="disabled" v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange">全选</a-checkbox>
								</a-form-item-rest>
								<a-checkbox-group :disabled="disabled" v-model:value="modelRef.dimensionalList">
									<a-checkbox :value="1">2D</a-checkbox>
									<a-checkbox :value="2">3D</a-checkbox>
									<a-checkbox :value="3">4D</a-checkbox>
									<a-checkbox :value="4">IMAX2D</a-checkbox>
									<a-checkbox :value="5">IMAX3D</a-checkbox>
								</a-checkbox-group>
							</a-form-item>

							<a-form-item label="放映时间">
								<div class="ui-time__header">
									<span class="ui-time__span">日期</span>
									<a-date-picker :disabled="disabled" v-model:value="modelRef.startDate" />
									<span class="ui-time__span">至</span>
									<a-date-picker :disabled="disabled" v-model:value="modelRef.endDate" />
									<a class="ui-time__span" @click="onAddSet">
										<Icon icon="PlusOutlined" style="margin-right: 2px;"></Icon>添加适用时间段
									</a>
								</div>
								<div class="ui-time__set" v-for="(item,index) in modelRef.timeList" :key="index">
									<div>
										<span class="ui-time__span">周期</span>
										<a-checkbox-group :disabled="disabled" v-model:value="item.week">
											<a-checkbox :value="7">周日</a-checkbox>
											<a-checkbox :value="1">周一</a-checkbox>
											<a-checkbox :value="2">周二</a-checkbox>
											<a-checkbox :value="3">周三</a-checkbox>
											<a-checkbox :value="4">周四</a-checkbox>
											<a-checkbox :value="5">周五</a-checkbox>
											<a-checkbox :value="6">周六</a-checkbox>
										</a-checkbox-group>
									</div>
									<div class="ui-margin__top20">
										<span class="ui-time__span">时段</span>
										<a-time-picker :disabled="disabled" format="HH:mm" v-model:value="item.startTime" />
										<span class="ui-time__span">至</span>
										<a-time-picker :disabled="disabled" format="HH:mm" v-model:value="item.endTime" />
										<a class="ui-time__span" @click="onDelSetting(index)">
											<Icon icon="DeleteOutlined"></Icon> 删除
										</a>
									</div>
								</div>
								<div style="color:red;">
									注：如存在多条放映时间段，以重叠的时间段为准
								</div>
							</a-form-item>
						</div>
						<div v-else-if="modelRef.versionType === 4">
							<a-form-item label="场次" name="showList">
								<a-form-item-rest>
									<screen :disabled="disabled" :id="type === 'preferential' ? modelRef.cinemaId : id" v-model:value="modelRef.showList"></screen>
								</a-form-item-rest>
							</a-form-item>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	// import dayjs from 'dayjs';
	import { Icon } from '@/components/icon/icon.js';
	import screen from '@/components/screen/index.vue';
	import movie from '@/components/movie/index.vue';
	import cinema from '@/components/cinema/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
  import selectCinema from "@/components/selectCinema/index.vue";
	export default {
		components: { selectCinema, Icon, screen, movie, cinema},
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: null
			},
			type: {
				type: String,
				default: ''
			},
			id: { // 影院ID
				type: null,
			},
			disabled: {
				type: Boolean,
				default: false
			},
      isEdit: {
        type: Boolean,
        default: false
      },
  polCinedmaIds: {
    type: Array,
    default: () => {
      return []
    }
  },
		},
		watch: {
			'modelRef.dimensionalList'(newVal) {
				this.indeterminate = !!newVal.length && newVal.length < 5;
				this.checkAll = newVal.length === 5;
			}
		},
		created() {
			if (this.value) {
				this.modelRef = this.$deepClone(this.value);
        this.policyIds = this.value.cinemaId
				if (this.modelRef.movieList && this.modelRef.movieList.length) {
					this.movieList = this.modelRef.movieList;
					delete this.modelRef.movieList;
				}
				if (this.modelRef.hallDataList && this.modelRef.hallDataList.length) {
					this.hallDataList = this.modelRef.hallDataList;
					delete this.modelRef.hallDataList;
				}
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				checkAll: false,
				indeterminate: false,
				organizationList: [],
				cinemaAllList: [],
				modelRef: {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: [],
          cinemaList:[],//可用影院列表
          cinemaTableList:[],//可用影院表格列表
          cinemaAllList: [],
          cinemaId:[],
          cinemaNames:'',
          isFilmMinPrice: 0, //
        },
				settingList: [],
				movieList: [],
				movieColumns: [{
					title: '影片编码',
					dataIndex: 'code'
				}, {
					title: '影片名称',
					dataIndex: 'shortName'
				},{
					title: '语言',
					dataIndex: 'language',
					width: 80,
					align: 'center'
				}, {
					title: '操作',
					key: 'action'
				}],
				hallDataList: [],
				hallColumns: [{
					title: '影厅编码',
					dataIndex: 'code'
				}, {
					title: '影厅名称',
					dataIndex: 'name'
				}, {
					title: '操作',
					key: 'action'
				}],

        cinemaColumns:[//可用影院表格表头
          {
            title: '影院编码',
            dataIndex: 'code'
          },
          {
            title: '影院名称',
            dataIndex: 'name'
          },
          {
            title: '操作',
            key: 'action'
          }
        ],
        policyIds:'',
        priceInfo: [],
        priceColumns: [
          {
            title: '序号',
            key: 'No'
          },
          {
            title: '价格区域',
            key: 'priceInfo',
            width: 450,
          },
          {
            title: '补差金额',
            key: 'diffPrice',
            width: 150,
          },
          {
            title: '操作',
            key: 'action'
          }
        ],
			}
		},
		methods: {
			onShowModal() {
        if (this.type !== 'preferential' && this.type !== 'tiktok' && this.type !='timesCard' && this.type !='yearCard') {
          // this.id = this.value.cinemaId
          this.getCinemaList(this.value.organizationId)
        }
				// if (this.type !== 'preferential' && !this.id) {
				// 	return this.$message.warn('请先选择所属影院');
				// }
				if (this.type === 'preferential') {
					this.getOrganizationList();
					if (this.value.cinemaId) {
						this.getCinemaList(this.value.organizationId)
					} else {
						this.value.organizationId = undefined;
						this.value.cinemaId = undefined;
					}
				}
				this.visible = true;
				if (this.value) {
					this.modelRef = this.$deepClone(this.value);
					if (this.modelRef.movieList && this.modelRef.movieList.length) {
						this.movieList = this.modelRef.movieList;
						//delete this.modelRef.movieList;
					} else {
						this.movieList = [];
					}
					if (this.modelRef.hallDataList && this.modelRef.hallDataList.length) {
						this.hallDataList = this.modelRef.hallDataList;
						//delete this.modelRef.hallDataList;
					} else {
						this.hallDataList = [];
					}
          this.modelRef.isFilmMinPrice = this.modelRef.isFilmMinPrice ? true : false;
          if (this.modelRef.isFilmMinPrice) {
            this.priceInfo = JSON.parse(this.modelRef.priceInfo).map(item => {
              return {
                max: item.minPriceRange.max,
                min: item.minPriceRange.min,
                isMax: item.minPriceRange.isMax,
                diffPrice: item.diffPrice,
              }
            })
          }
				}
			},
			onCancel() {
				this.visible = false;
        this.policyIds = ''
				if (this.value) {
					this.modelRef = this.$deepClone(this.value);
					if (this.modelRef.movieList && this.modelRef.movieList.length) {
						this.movieList = this.modelRef.movieList;
						delete this.modelRef.movieList;
					}
					if (this.modelRef.hallDataList && this.modelRef.hallDataList.length) {
						this.hallDataList = this.modelRef.hallDataList;
						delete this.modelRef.hallDataList;
					}
				} else {
					this.movieList = [];
					this.hallDataList = [];
					this.priceInfo = [];
					this.modelRef= {
						versionType: 3,
						isDisabled: true,
						filmType: 1,
						filmList: [],
						hallType: 1,
						hallList: [],
						isHoliday: false,
						visualType: 1,
						dimensionalList: [],
						showList: [],
						startDate: null,
						endDate: null,
						timeList: [],
            cinemaList:[],//可用影院列表
            cinemaTableList:[],//可用影院表格列表
            cinemaId:[],
            cinemaNames:'',
            isFilmMinPrice: 0,
					}
				}
			},
			onOk(type) {
				this.$refs.formRef.validateFields().then(() => {
          if (this.type !== 'preferential' && this.type !== 'tiktok' && this.type !='timesCard' && this.type !='yearCard')  {
            if (this.modelRef.cinemaTableList.length == 0){
              this.$message.error('使用影院列表不能为空，请选择影院！')
              return;
            }

          }
					if (this.modelRef.versionType === 3) {
						if (this.modelRef.filmType !== 1 && this.modelRef.filmList.length === 0) {
							this.$message.error('影片列表不能为空，请选择影片！')
							return;
						}
						if (this.modelRef.hallType !== 1 && this.modelRef.hallList.length === 0) {
							this.$message.error('影厅列表不能为空，请选择影厅！')
							return;
						}


					}
          // this.modelRef.cinemaId = this.id?this.id:''  // ???
          if (this.type !== 'preferential' && this.type !== 'tiktok' && this.type !='timesCard' && this.type !='yearCard'){
            let cinemaNames = []
            this.modelRef.cinemaTableList.forEach((item)=>{
              if(item.name){
                cinemaNames.push(item.name)
              }
            })
            this.modelRef.cinemaName = cinemaNames.join(',')
            this.modelRef.cinemaId = this.modelRef.cinemaTableList[0].id
          }

          console.log(this.modelRef)
          // this.modelRef.cinemaId = this.modelRef.cinemaId.join(',')
					let data = this.$deepClone(this.modelRef);
					if (this.type === 'preferential'){
						data.singleCount = 0;
						data.filmCount = 0;
					};
          if (data.isFilmMinPrice) {
            // [{"id":1,"diffPrice":20.00,"minPriceRange":{"min":0.00,"max":10.00,"isMax":1}}]
            let checkFilmMin = true;
            let errorTips = '请填写完整最低票价补差规则';
            const priceInfo = [];
            this.priceInfo.forEach((item, index)=>{
              // 先传给后台, 不做校验处理
              const priceInfoItem = {
                id: index + 1,
                diffPrice: item.diffPrice,
                minPriceRange: {
                  min: item.min,
                  max: item.max,
                  isMax: item.isMax ? 1 : 0,
                }
              };
              if (item.min === '' || (item.max === '' && item.isMax === false) || (item.diffPrice !== 0 && !item.diffPrice)) {
                checkFilmMin = false;
                errorTips = '请填写完整最低票价补差规则';
              }
              if ((item.min > item.max) && !item.isMax) {
                errorTips = `请检查序号${index + 1}记录，价格区间最小值是否大于最大值`;
                checkFilmMin = false;
              }
              priceInfo.push(priceInfoItem)
            })
            if (!checkFilmMin) {
              this.$message.error(errorTips);
              return;
            }
            data.priceInfo = JSON.stringify(priceInfo);
          } else {
            data.isFilmMinPrice = 0;
            data.priceInfo = JSON.stringify([]);
          }
					this.$emit('update:value', data);
					this.$emit('ok', data);
					if (this.value) {
						this.modelRef = this.modelRef = this.$deepClone(this.value);
					} else {
						this.modelRef= {
							versionType: 3,
							isDisabled: true,
							filmType: 1,
							filmList: [],
							hallType: 1,
							hallList: [],
							isHoliday: false,
							visualType: 1,
							dimensionalList: [],
							showList: [],
							startDate: null,
							endDate: null,
							timeList: [],
              cinemaList:[],//可用影院列表
              cinemaTableList:[],//可用影院表格列表
              cinemaId:[],
              cinemaNames:''
						}
					}
					this.visible = type;
				});
			},
			onAddSet() {
				if (this.disabled) return;
				this.modelRef.timeList.push({
					week: [7, 1, 2, 3, 4, 5, 6],
					startTime: this.moment('00:00', 'HH:mm'),
					endTime: this.moment('23:59', 'HH:mm')
				})
			},
			onDelSetting(index) {
				if (this.disabled) return;
				this.modelRef.timeList.splice(index, 1);
			},
			onCheckAllChange(e) {
				if (e.target.checked) {
					this.modelRef.dimensionalList = [1,2,3,4,5];
				} else {
					this.modelRef.dimensionalList = [];
				}
			},
			onDeleteMovie(item, index) {
				let i = this.modelRef.filmList.indexOf(item.id);
				this.modelRef.filmList.splice(i, 1);
				this.movieList.splice(index, 1);
			},
			onDeleteHall(item, index) {
				let i = this.modelRef.hallList.indexOf(item.id);
				this.modelRef.hallList.splice(i, 1);
				this.hallDataList.splice(index, 1);
			},
			onOrganizationChange(id) {
				this.onCinemaChange();
				this.getCinemaList(id);
			},
			onCinemaChange(cinemaId) {
				this.$message.warn('您已切换影院，请重新设置政策');
				this.modelRef.filmType = 1;
				this.modelRef.filmList = [];
				this.movieList = [];
				this.modelRef.hallType = 1;
				this.modelRef.hallList = [];
				this.hallDataList = [];
				this.modelRef.showList = [];
				if (!cinemaId) {
					this.modelRef.versionType = 3;
				}
			},
			filterOption(input, option) {
				return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
          console.log(this.value,"this.value")
          console.log(this.value.cinemaId,"this.value.policyIdsthis.value.policyIds")
          if (this.modelRef.cinemaId){
            //已经使用的 hasArray
            console.log(this.value.cinemaId,"this.value.policyIdsthis.value.policyIds")
            this.modelRef.cinemaTableList = this.hasArray(this.modelRef.cinemaId,ret.data.list,'policy')
            console.log(this.modelRef.cinemaTableList,"已经使用的 hasArray")
            //可以选择使用
            this.modelRef.cinemaAllList = this.filterArray(this.modelRef.cinemaId,ret.data.list,'policy')
            console.log(this.modelRef.cinemaAllList,"可以选择使用 filterArray")
          }

				}
			},
      filterArray(obj, arr,type){// 将对象的值转换为数字数组
        if (type == 'policy') {
          return arr.filter(item => item.id !== obj);
        }else {
          return arr.filter(item => !obj.includes(item.id)); // 过滤数组，返回包含对象值的元素

        }
      },
      hasArray(obj, arr,type){
        if (type == 'policy') {
          return arr.filter(item => item.id == obj);
        }else {
          return arr.filter(item => obj.includes(item.id)); // 过滤数组，返回包含对象值的元素
        }
      },
      // 删除选择的可选影院
      onDeleteCinema(item, index) {
        // let i = this.value.cinemaId.indexOf(item.id);
        // this.value.cinemaId = ''

        this.modelRef.cinemaTableList =[]
        this.modelRef.filmList = [];
        this.movieList = []
        this.modelRef.hallList = [];
        this.hallDataList = [];
        // console.log(item.id)
        // console.log(this.policyIds)
        this.policyIds = ''
        // this.value.cinemaId = 5
        // let i = this.value.cinemaId.indexOf(item.id);
        // console.log(i,"i")
        // this.value.cinemaId.splice(i, 1);
        // this.cinemaTableList.splice(index, 1);
        // const id = this.cinemaTableList.findIndex(discountItem => discountItem.cinemaId === item.id);
        // if (id !== -1) {
        //   // 如果存在相同的cinemaId，则更新该元素
        //   this.cinemaTableList.splice(index, 1);
        // }
      },
      onAddPriceInfo() {
        this.priceInfo.push({
          min: '',
          max: '',
          isMax: 0,
          diffPrice: '',
        })
      },
      onDelPriceInfo(index) {
        if (this.priceInfo.length == 1) {
          this.$message.error('最少需要一个最低票价补差配置');
          return
        }
        this.priceInfo.splice(index, 1);
      },
      onPriceInfoSwitchChange() {
        if (!this.priceInfo) {
          this.priceInfo = [{
            min: '',
            max: '',
            isMax: 0,
            diffPrice: '',
          }];
        } else if (!this.priceInfo.length) {
          this.onAddPriceInfo();
        }
      },
      onMaxChange(record, index, type) {
        if (type == 'max') {
          if (record.isMax) record.isMax = false; //设置是否最大为否
        }
        if (type == 'isMax') {
          if (record.isMax == 1) record.max = ''; //情况最大值
        }
      },
		}
	}
</script>

<style scoped>
	.ui-flex {
		display: flex;
	}

	.ui-input {
		width: 180px;
		display: inline-block;
	}

	.ui-margin__top20 {
		margin-top: 20px;
	}

	.ui-margin__top40 {
		margin-top: 40px;
	}

	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #f5f5f5;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>
